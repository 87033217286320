import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {getTestBundles, State} from '../../../shared/store';
import {Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, map, mergeMap, startWith} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, interval, merge, Observable, of, Subscription} from 'rxjs';
import {ModalOptions, Route} from '../../../shared/constants/constants';
import {CommonUtils} from '../../../shared/utils/common-utils';
import {DataSource} from '@angular/cdk/table';
import {CollectionViewer} from '@angular/cdk/collections';
import {Test} from '../../../shared/models/test';
import {TestStatus, TestStatusItems} from '../../../shared/enums/test-status';
import {TestModalComponent} from '../test-modal/test-modal.component';
import {TestBundlesService} from '../../../shared/services/test-bundles.service';
import {TestBundle} from '../../../shared/models/test-bundle';
import {TestBundleStatus} from '../../../shared/enums/test-bundle-status';
import {MappingUtils} from '../../../shared/utils/mapping-utils';
import {EmailsService} from '../../../shared/services/emails.service';
import {SearchUtils} from '../../../shared/utils/search-utils';
import {TestSendModalComponent} from '../test-send-modal/test-send-modal.component';
import {SortUtils} from '../../../shared/utils/sort-utils';
import {ConfirmModalComponent} from '../../../shared/components/modals/confirm-modal/confirm-modal.component';
import {CandidatesService} from '../../../shared/services/candidates.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {Candidate} from '../../../shared/models/candidate';
import {TestScheduleModalComponent} from '../test-schedule-modal/test-schedule-modal.component';
import {UpdateTestBundleAction} from '../../../shared/store/actions/test-bundles.actions';
import * as moment from 'moment';

const scheduleTask = Promise.resolve(null);

@Component({
    selector: 'app-tests',
    templateUrl: './tests.component.html',
    styleUrls: ['./tests.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestsComponent implements OnInit, OnDestroy {
    public dataSource: TestBundleDataSource | null;
    public db: TestBundleDatabase;
    public displayedColumns = [
        'toggle',
        'code',
        'candidate',
        'testInfo',
        'numberOfTries',
        'created',
        'status',
        'isReminderSend',
        'emailSentBy',
        'actions'
    ];
    public displayedColumnsDetails = [
        'toggleEmpty',
        'tests',
        'actionsEmpty'
    ];
    public expanded: TestBundle = null;

    /* Filters */
    public generalFilter = new UntypedFormControl();
    public stateFilter = new UntypedFormControl();
    public checkSending: Observable<TestBundle[]>
    public checkSendingSubscription: Subscription

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(private store: Store<State>,
                private router: Router,
                private dialog: MatDialog,
                private cdr: ChangeDetectorRef,
                private testBundlesService: TestBundlesService,
                private candidatesService: CandidatesService,
                private emailsService: EmailsService) {
        this.db = new TestBundleDatabase(store);
    }

    get statuses(): any[] {
        return TestStatusItems;
    }

    ngOnInit() {
        this.dataSource = new TestBundleDataSource(this.db, this.paginator, this.sort);

        const generalFilterObservable = this.generalFilter.valueChanges
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                startWith('')
            );

        const stateFilterObservable = this.stateFilter.valueChanges
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                startWith('')
            );

        combineLatest([generalFilterObservable, stateFilterObservable])
            .pipe(map(([general, state]) => ({general, state})))
            .subscribe(filter => {
                if (this.dataSource) {
                    this.dataSource.filter = filter;
                }
            });

        scheduleTask.then(() => {
            this.testBundlesService.fetchTestBundles();
        });

        this.checkSending = interval(5000).pipe(mergeMap(() => this.checkStatus()));
        this.checkSendingSubscription = this.checkSending.subscribe((data) => this.processStatusData(data))
    }

    ngOnDestroy(): void {
        if (this.checkSendingSubscription) {
            this.checkSendingSubscription.unsubscribe();
        }
    }

    checkStatus(): Observable<TestBundle[]> {
        const items = (this.db.bundlesData || []).filter(bundle => bundle.status === TestBundleStatus.SENDING)
        return CommonUtils.isEmpty(items) ? of([]) : this.testBundlesService.fetchTestBundlesSending();
    }

    processStatusData(bundles: TestBundle[]) {
        const items = (this.db.bundlesData || []).filter(b => b.status === TestBundleStatus.SENDING);
        const sent = items.filter(item => bundles.findIndex(b => b.id === item.id) === -1);

        if (CommonUtils.isNotEmpty(sent)) {
            sent.forEach(item => this.store.dispatch(new UpdateTestBundleAction({
                ...item,
                status: TestBundleStatus.SENT
            })))
        }
    }

    getStatusTag(bundle: TestBundle): string {
        if (bundle && bundle.status) {
            switch (bundle.status) {
                case TestBundleStatus.CREATED:
                    return '<span class="kt-badge kt-badge--unified-dark kt-badge--inline">Created</span>';
                case TestBundleStatus.SENDING:
                    return '<span class="kt-badge kt-badge--unified-dark kt-badge--inline">Sending</span>';
                case TestBundleStatus.SENT:
                    return '<span class="kt-badge kt-badge--brand kt-badge--inline">Sent</span>';
                case TestBundleStatus.SCHEDULED:
                    if (bundle.when_to_send) {
                        const scheduleDate = moment.utc(bundle.when_to_send).local().format('DD.MM.YYYY HH:mm');
                        return `<span class="kt-badge kt-badge--brand kt-badge--inline"><i class="fa flaticon-time scheduled"></i>${scheduleDate}</span>`;
                    }
                    return '<span class="kt-badge kt-badge--brand kt-badge--inline">Scheduled</span>';
                case TestBundleStatus.STARTED:
                    return '<span class="kt-badge kt-badge--info kt-badge--inline">Started</span>';
                case TestBundleStatus.FINISHED:
                    return '<span class="kt-badge kt-badge--warning kt-badge--inline">Finished</span>';
                case TestBundleStatus.ERROR:
                    return '<span class="kt-badge kt-badge--danger kt-badge--inline">Error Occurred</span>';
            }
        }
        return '';
    }

    getTaskStatusClass(test: Test): string {
        if (test && test.status) {
            switch (test.status) {
                case TestStatus.CREATED:
                case TestStatus.SENDING:
                    return 'kt-widget1__number';
                case TestStatus.SENT:
                case TestStatus.SCHEDULED:
                    return 'kt-widget1__number kt-font-brand';
                case TestStatus.STARTED:
                    return 'kt-widget1__number kt-font-info';
                case TestStatus.FINISHED:
                    return 'kt-widget1__number kt-font-warning';
                case TestStatus.ERROR:
                    return 'kt-widget1__number kt-font-danger';
            }
        }
        return '';
    }

    isExpandedRow = (i: number, row: TestBundle): boolean => {
        const expandedId: number = this.expanded ? this.expanded.id : null;
        return expandedId === row.id;
        // tslint:disable-next-line:semicolon
    };

    createTestBundle(): void {
        this.candidatesService.fetchSearchCandidates();

        /* Show modal */
        const dialogRef = this.dialog.open(TestModalComponent, this.modalConfigurationTestBundle({}));
        dialogRef.afterClosed().subscribe(response => {
            if (response.result === 'yes') {
                this.testBundlesService.createTestBundle(response.data);
            }
        });
    }

    selectTestBundle(bundle: TestBundle): void {
        if (this.expanded && this.expanded.id === bundle.id) {
            this.expanded = null;
        } else {
            this.expanded = bundle;
        }
        this.dataSource.rowChange.next(this.expanded);
    }

    createCandidateUrl(candidate: Candidate): string {
        return candidate && candidate.id ? Route.HOME_ROOT.concat(`/candidates/${candidate.id}/preview`) : '';
    }

    preventPropagation(event: Event): void {
        /* Prevent propagation */
        CommonUtils.preventPropagation(event);
    }

    deleteTestBundle(event: Event, bundle: TestBundle): void {
        /* Prevent propagation */
        CommonUtils.preventPropagation(event);

        /* Delete Test */
        const dialogRef = this.dialog.open(ConfirmModalComponent, this.modalConfigurationConfirm(bundle));
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'yes') {
                this.testBundlesService.deleteTestBundle(bundle);
            }
        });
    }

    sendEmailTestBundle(event: Event, bundle: TestBundle): void {
        CommonUtils.preventPropagation(event);

        /* Send Email */
        const dialogRef = this.dialog.open(TestSendModalComponent, this.modalConfigurationEmail(bundle));
        dialogRef.afterClosed().subscribe(data => {
            if (data.result === 'yes') {
                this.sendEmail(bundle, data.data);
            }
        });
    }

    scheduleEmailTestBundle(event: Event, bundle: TestBundle): void {
        CommonUtils.preventPropagation(event);

        /* Send Email */
        const dialogRef = this.dialog.open(TestScheduleModalComponent, this.modalConfigurationEmail(bundle));
        dialogRef.afterClosed().subscribe(data => {
            if (data.result === 'yes') {
                this.sendEmail(bundle, data.data);
            }
        });
    }

    sendEmail(bundle: TestBundle, data: { when_to_send: any, when_to_send_time: number, email: string }): void {
        let whenToSend = '';
        if (data.when_to_send && data.when_to_send_time) {
            const scheduled = data.when_to_send.format('YYYY-MM-DDT') + data.when_to_send_time;
            whenToSend = moment(scheduled).format('YYYY-MM-DDTHH:mm:ssZ');
        }
        this.emailsService.notifyCandidate(bundle.id, data.email, whenToSend);
    }

    formatTestInfo(test: Test): string {
        if (test) {
            const type = MappingUtils.mapEnumValue(test.type, 'TestType');
            const result = [`[${test.search_code}] ${type}`];
            if (test.started) {
                result.push(' | ');
                result.push(MappingUtils.mapDateTimeValue(test.started));
                if (test.finished) {
                    result.push(' - ');
                    result.push(MappingUtils.mapDateTimeValue(test.finished));
                }
            }
            return result.join('');
        }
        return '';
    }

    formatTestShortInfo(tests: Test[]): string {
        return (tests || []).map(test => test.type).join(', ');
    }

    modalConfigurationEmail(bundle: TestBundle): any {
        return Object.assign({}, ModalOptions.EMAIL, {
            data: bundle
        });
    }

    modalConfigurationConfirm(bundle: TestBundle): any {
        return Object.assign({}, ModalOptions.CONFIRM, {
            data: {
                title: 'Delete',
                message: `Are you sure you want to delete ${bundle.search_code}?`
            }
        });
    }

    modalConfigurationTestBundle(bundle: any): any {
        return Object.assign({}, ModalOptions.TEST, {
            data: bundle
        });
    }
}

export class TestBundleDatabase {
    public bundles: Observable<TestBundle[]>;
    public bundlesData: TestBundle[] = [];

    constructor(private store: Store<State>) {
        this.bundles = store.select(getTestBundles);
        this.bundles.subscribe(data => this.bundlesData = data);
    }
}

export class TestBundleDataSource extends DataSource<TestBundle> {
    public filterChange = new BehaviorSubject({general: '', state: ''});
    public rowChange = new BehaviorSubject<TestBundle>(null);
    public filteredData: TestBundle[] = [];
    public renderedData: TestBundle[] = [];

    constructor(private db: TestBundleDatabase,
                private paginator: MatPaginator,
                private sort: MatSort) {
        super();

        // Reset to the first page when the test changes the filter.
        this.filterChange.subscribe(() => this.paginator.pageIndex = 0);
    }

    get filter(): TestBundleFilter {
        return this.filterChange.value;
    }

    set filter(filter: TestBundleFilter) {
        this.filterChange.next(filter);
    }

    connect(collectionViewer: CollectionViewer): Observable<TestBundle[]> {
        const displayDataChanges = [
            this.db.bundles,
            this.filterChange,
            this.sort.sortChange,
            this.paginator.page,
            this.rowChange
        ];

        //noinspection TypeScriptValidateTypes
        return merge<any>(...displayDataChanges).pipe(map(() => {
            // Filter data
            this.filteredData = this.db.bundlesData.slice().filter((item: TestBundle) => {
                return this.filterTestBundle(this.filter.state, item) &&
                    SearchUtils.searchTestBundle(item, this.filter.general);
            });

            // Sort filtered data
            const sortedData = this.sortData(this.filteredData.slice());

            // Grab the page's slice of the filtered sorted data.
            const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
            this.renderedData = sortedData.splice(startIndex, this.paginator.pageSize);
            return this.renderedData;
        }));
    }

    disconnect(collectionViewer: CollectionViewer): void {
        /* Nothing */
    }

    filterTestBundle(status: any, test: TestBundle): boolean {
        return status ? test.status === status : true;
    }

    /** Returns a sorted copy of the database data. */
    sortData(data: TestBundle[]): TestBundle[] {
        if (!this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const direction = this.sort.direction === 'asc' ? 1 : -1;
            switch (this.sort.active) {
                case 'created':
                    return direction * SortUtils.dateComparator(a.created, b.created);
                case 'code':
                    return direction * SortUtils.stringComparator(a.search_code, b.search_code);
                case 'candidate':
                    const codeA = a.candidate ? a.candidate.code : '';
                    const codeB = b.candidate ? b.candidate.code : '';
                    return direction * SortUtils.stringComparator(codeA, codeB);
                case 'numberOfTries':
                    return direction * SortUtils.numberComparator(a.number_of_tries, b.number_of_tries);
            }
            return 0;
        });
    }
}

interface TestBundleFilter {
    general: string;
    state: string;
}
